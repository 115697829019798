<template>
    <app-page>
        <template #page-header>
            <page-header name="Certificates" :loading="loading" v-model="search">
                <b-btn class="mb-2 mr-1" variant="secondary" @click="createWildcardModalVisible = true">Create wildcard certificate<i class="fas fa-plus ml-1"></i></b-btn>
            </page-header>
        </template>
        <b-overlay :show="loading" rounded="sm">
            <b-tabs v-model="tabIndex" class="main-tabs" content-class="mt-3" align="center" pills>
                <b-tab title="HTTPS certificates" active>
                    <div class="my-3">
                        <b-btn @click="getCertificates">Check validity</b-btn>
                        <b-btn @click="renewAllCertificatesAlmostExpired" class="ml-2">Renew certificates almost expired</b-btn>
                    </div>
                    <b-list-group class="compact">
                        <b-list-group-item v-for="certificate in filteredCertificates" :key="certificate.secretName" class="text-left">
                            <p>{{ certificate.secretName }} <small>(expires on {{ certificate.expire_at|date }}, should renew on {{ certificate.renew_at | date }})</small></p>
                            <b-badge v-for="host in certificate.hosts" :key="host" variant="light">
                                <a :href="'https://'+host">{{ host }}</a>
                            </b-badge>
                            <e-button-confirm @confirmed="deleteCertificate(certificate)" title="Delete" class="float-right m-1" size="sm" variant="danger">
                                <i class="fas fa-trash"></i>
                            </e-button-confirm>

                            <e-button-async @async-click="renewCertificate(certificate, $event)" class="float-right m-1" size="sm"> Renew</e-button-async>
                            <b-badge v-if="certificate.status === `valid` && certificate.days_remaining" :variant="certificate.days_remaining < 30 ? 'danger' : 'success'" class="float-right m-1">expires in {{ certificate.days_remaining }} days</b-badge>
                            <b-badge :variant="certificate.status === `valid` ? 'success': 'danger'" class="float-right m-1">{{ certificate.status }}</b-badge>
                        </b-list-group-item>
                    </b-list-group>
                </b-tab>
                <b-tab title="Wildcard certificates">
                    <b-list-group class="compact">
                        <b-list-group-item v-for="certificate in filteredWildcardCertificates" :key="certificate.domain" class="text-left">
                            <p>*.{{ certificate.domain }} <small>(expires on {{ certificate.expire_at|date }})</small></p>
                            <b-btn @click="downloadCRT(certificate)" class="mr-1"><i class="fas fa-download"></i> Download CRT</b-btn>
                            <b-btn @click="downloadKEY(certificate)" class="mr-1"><i class="fas fa-download"></i> Download KEY</b-btn>
                            <b-btn @click="downloadCSR(certificate)" class="mr-1"><i class="fas fa-download"></i> Download CSR</b-btn>
                            <e-button-confirm @confirmed="deleteWildcardCertificate(certificate)" title="Delete" class="float-right m-1" size="sm" variant="danger">
                                <i class="fas fa-trash"></i>
                            </e-button-confirm>
                            <e-button-async @async-click="renewWildcardCertificate(certificate, $event)" class="float-right m-1" size="sm" :disabled="!!certificate.renewing_at"> Renew</e-button-async>
                            <e-button-async @async-click="renewWildcardCertificate(certificate, $event, 10 * 60)" class="float-right m-1" size="sm" :disabled="!!certificate.renewing_at"> Slow Renew</e-button-async>
                            <b-badge v-if="certificate.status === `valid` && certificate.days_remaining" :variant="certificate.days_remaining < 30 ? 'danger' : 'success'" class="float-right m-1">expires in {{ certificate.days_remaining }} days</b-badge>
                            <b-badge :variant="certificate.status === `valid` ? 'success': 'danger'" class="float-right m-1">{{ certificate.status }}</b-badge>
                            <b-badge variant="warning" class="float-right m-1" v-if="certificate.renewing_at">Renew at {{ certificate.renewing_at | datetime }}</b-badge>
                        </b-list-group-item>
                    </b-list-group>
                </b-tab>
            </b-tabs>
        </b-overlay>

        <b-modal v-model="createWildcardModalVisible" title="Add/Remove new IP">
            <b-form-group label="Domain">
                <b-input v-model="newCertificate.domain"></b-input>
            </b-form-group>

            <div class="text-warning mt-2"><i class="fas fa-warning"></i> Wildcard certificate creation can take about a minute</div>

            <template #modal-footer>
                <b-btn variant="default" @click="createWildcardModalVisible = false" :disabled="saving">Cancel</b-btn>

                <e-button-async variant="primary" @async-click="createWildcardCertificate()" :disabled="saving || !newCertificate.domain">
                    Create
                    <i class="fas fa-save"></i>
                </e-button-async>
            </template>
        </b-modal>
    </app-page>
</template>

<script>
import Network from "../../vue-components/helpers/Network.js";
import EButtonConfirm from "../../vue-components/components/e-button-confirm.vue";
import EButtonAsync from "../../vue-components/components/e-button-async.vue";

export default {
    name: `certificates`,
    components: {EButtonAsync, EButtonConfirm},
    data() {
        return {
            loading: true,
            saving: false,
            certificates: [],
            wildcardCertificates: [],
            validityChecked: false,
            search: ``,
            tabIndex: 0,
            createWildcardModalVisible: false,
            newCertificate: {
                domain: ``
            }
        };
    },
    computed: {
        filteredCertificates() {
            if (!this.search) {
                return this.certificates;
            }

            return this.certificates.filter(c => c.secretName.toLowerCase().includes(this.search.toLowerCase()) || (c.hosts.filter(h => h.toLowerCase().includes(this.search.toLowerCase())).length > 0));
        },
        filteredWildcardCertificates() {
            if (!this.search) {
                return this.wildcardCertificates;
            }

            return this.wildcardCertificates.filter(c => c.domain.toLowerCase().includes(this.search.toLowerCase()));
        }
    },
    created() {
        this.unwatch = this.$store.watch(
            (state, getters) => getters.cluster,
            () => {
                this.init();
            }
        );
    },
    activated() {
        this.unwatch = this.$store.watch(
            (state, getters) => getters.cluster,
            () => {
                this.init();
            }
        );

        this.init();
    },
    deactivated() {
        this.unwatch();
    },
    methods: {
        async init() {
            this.loading = true;
            this.saving = false;
            this.certificates = [];
            this.wildcardCertificates = [];
            this.validityChecked = false;

            await Promise.allSettled([this.getCertificates(false), this.getWildcardCertificates()]);
            this.loading = false;
        },
        async getCertificates(stopLoading = true) {
            this.loading = true;
            const resp = await Network.get(`/api/certificates/${this.$store.state.cluster}`, {check_validity: true});
            this.certificates = resp.data;
            if (stopLoading) {
                this.loading = false;
            }
        },
        async renewCertificate(certificate, callback) {
            try {
                await Network.post(`/api/certificate/renew/${this.$store.state.cluster}`, {name: certificate.secretName});
            } catch (e) {
                console.error(e);
            }

            if (callback) {
                callback();
            }
        },
        async deleteCertificate(certificate) {
            await Network.delete(`/api/certificate/delete/${this.$store.state.cluster}/${certificate.secretName}`);
            await this.getCertificates();
            this.loading = false;
        },
        async renewAllCertificatesAlmostExpired() {
            for (const certificate of this.certificates) {
                if (certificate.status !== `valid` || certificate.days_remaining < 30) {
                    await this.renewCertificate(certificate);
                }
            }
        },
        async getWildcardCertificates() {
            this.loading = true;

            const resp = await Network.get(`/api/wildcard-certificates`);
            this.wildcardCertificates = resp.data;
        },
        async createWildcardCertificate(callback) {
            try {
                this.saving = true;
                await Network.post(`/api/wildcard-certificate/create`, this.newCertificate);

                this.newCertificate.domain = ``;

                this.tabIndex = 1;
                this.createWildcardModalVisible = false;
                this.saving = false;
            } catch (e) {
                console.log(e);
            }

            if (callback) {
                callback();
            }
        },
        async renewWildcardCertificate(certificate, callback, dnsPropagationTime = 30) {
            try {
                await Network.post(`/api/wildcard-certificate/renew`, {domain: certificate.domain, force: true, dnsPropagationTime: dnsPropagationTime});
            } catch (e) {
                console.error(e);
            }

            if (callback) {
                await this.getWildcardCertificates();
                this.loading = false;
                callback();
            }
        },
        async deleteWildcardCertificate(certificate) {
            await Network.delete(`/api/wildcard-certificate/delete/${certificate.domain}`);
            await this.getWildcardCertificates();
            this.loading = false;
        },
        downloadCRT(certificate) {
            this.download(certificate, `crt`)
        },
        downloadKEY(certificate) {
            this.download(certificate, `key`)
        },
        downloadCSR(certificate) {
            this.download(certificate, `csr`)
        },
        async download(certificate, extension) {
            const options = {
                headers: {
                    Authorization: `Bearer ${await Network.getToken()}`
                }
            };

            const resp = await fetch(`${Network.baseUrl}/api/wildcard-certificate/download/${certificate.domain}/${extension}`, options)

            if (!resp.ok) {
                console.log(await resp.json())
                return
            }

            const blob = await resp.blob()

            const a = document.createElement(`a`);
            const url = URL.createObjectURL(blob);
            a.href = url;
            a.download = `${certificate.domain}.${extension}`;
            a.click();
            URL.revokeObjectURL(url);
        }
    }
}
</script>

<style scoped>

</style>
